import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import Slider from "../../../Components/Slider";

function ItalianStairs() {
  function importAll(r: any) {
    let images: any = {};
    r.keys().forEach((item: string, index: any) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(
    require.context(
      "../../../Components/Images/Interior Design/ItalianMoraccon",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const arraythis = [];
  for (const key in images) {
    arraythis.push(images[key]);
  }

  return (
    <div className="About">
      <Header />
      <h1 className="pageTitle">Italian Moraccon Staircase</h1>
      <div className="content">
        <Slider imagelist={arraythis} />
      </div>
      <Footer />
    </div>
  );
}
export default ItalianStairs;
