import "./Work.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { workImages } from "./HomeImages";
import { useNavigate } from "react-router";

function Work() {
  const navigate = useNavigate();

  function redirect(link: any) {
    navigate(link);
  }
  function width() {
    if (window.innerWidth > 500) {
      return 16;
    } else {
      return 12;
    }
  }
  const [imagelist, setimagelist] = useState<number>(width());
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const totalImages = workImages.length;
  const shortenedImageList = workImages.slice(0, imagelist);

  function showMore() {
    setimagelist(totalImages);
  }

  function showLess() {
    setimagelist(16);
  }

  return (
    <div className="work">
      {" "}
      <div className="container">
        {shortenedImageList.map((workImage, id) => {
          return (
            <div
              key={id}
              data-aos="fade-up"
              className="child"
              onClick={() => redirect(workImage.caption)}
            >
              <div className="houseImage">
                <img
                  src={require("../Components" + workImage.photo)}
                  alt="houseImage"
                />
              </div>
              <div className="middle">
                <h1 className="workImageTitle">{workImage.title}</h1>
              </div>
            </div>
          );
        })}
      </div>
      {imagelist > totalImages && <button onClick={showLess}>Show Less</button>}
      <button
        className="showmore"
        onClick={
          imagelist < totalImages
            ? () => {
                showMore();
              }
            : () => {
                showLess();
              }
        }
      >
        {imagelist < totalImages ? "Show More" : "Show Less"}{" "}
      </button>
    </div>
  );
}

export default Work;
