import "./Home.css";
import "../Components/Header";
import Work from "../Components/Work";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div className="Home">
      <Header />
      <h1 className="workHeading">Our Work</h1>
      <Work />
      <Footer />
    </div>
  );
}

export default Home;
