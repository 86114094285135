import logo from "../GlobalImages/MADS.jpeg";
import "./Header.css";
import Dropdown from "./Dropdown";
import { useNavigate } from "react-router";

export const paths: string[] = [
  "/",
  "/#/services",
  "/#/about",
  "/#/upcoming-work",
  "/#/contact",
];

const baseUrl = "http://localhost:3001";

function Header() {
  const navigate = useNavigate();

  function redirect() {
    navigate("/");
  }
  if (window.innerWidth < 500) {
    return (
      <header>
        <img src={logo} alt="Logo" className="logo" onClick={redirect} />
        <Dropdown />
      </header>
    );
  } else {
    return (
      <header>
        <a href={paths[0]}>
          <img src={logo} alt="Logo" className="logo" />
        </a>

        <div className="headerButtons">
          <a
            href={paths[0]}
            className={window.location.href === paths[0] ? "current" : ""}
          >
            HOME
          </a>
          <a
            href={paths[1]}
            className={
              window.location.href === baseUrl + paths[1] ? "current" : ""
            }
          >
            SERVICES
          </a>
          <a
            href={paths[2]}
            className={
              window.location.href === baseUrl + paths[2] ? "current" : ""
            }
          >
            ABOUT
          </a>
          <a
            href={paths[3]}
            className={
              window.location.href === baseUrl + paths[3] ? "current" : ""
            }
          >
            UPCOMING WORK
          </a>
          <a
            href={paths[4]}
            className={
              window.location.href === baseUrl + paths[4] ? "current" : ""
            }
          >
            CONTACT
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
