import Footer from "../Components/Footer";
import Header from "../Components/Header";
import arish from "../GlobalImages/arish.jpeg";
import "./About.css";

function About() {
  return (
    <div className="About">
      <Header />
      <h1 className="pageTitle">About</h1>
      <div className="content">
        <img src={arish} alt="arish" />
        <div className="contentText">
          <p>
            I am an Architect with a First Class graduate degree in Bachelors of
            Architecture and a MSc in Construction Project Management with BIM
            from Northumbria University, Newcastle Upon Tyne (U.K).
          </p>
          <p>
            During my training period with SafeOz, Australia, I worked as a
            leader of engineering group and learnt a plethora of Management
            skills under the supervision of some experienced individuals. We as
            a group researched, reviewed and concluded the reason behind the
            "fire incident held in Grenfell Tower, London". I have also worked
            for a year as a trainee Architect at Design Dimensions where I
            designed and drafted various projects. I have carried out projects
            using softwares such as AutoCAD, Sketch-up, Photoshop, V-ray,
            Lumion, Twinmotion and Revit. My work includes working drawings
            I.e., M.E.P (Mechanical, Electrical and Plumbing drawings).
          </p>
          <p>
            I have written 10K words dissertation on the topic "Examining the
            use of Virtual meetings in the Construction Industry during the
            COVID-19 pandemic". I have also written research papers such as:
            "Achieving sustainable urbanisation through smart cities in India",
            "Collaboration of drawings and site work with BIM in the AEC
            industry", "Delays in construction project due to wastes" and also
            designed an academic poster on "BIM process used in the project
            Cathay Pacific-Argo Terminal".
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default About;
