import { ImageObject } from "../Types";

export const archiImages: ImageObject[] = [
  {
    photo: "/Images/Architecture/Marque.jpeg",
    title: "Marque Implex Factory Noorpoor",
    caption: "",
  },
  {
    photo: "/Images/Architecture/AMG-Car/VIEW 1.jpg",
    title: "AMG Car Sales Newcastle",
    caption: "",
  },
  {
    photo: "/Images/Architecture/AMG-Car/VIEW 2.jpg",
    title: "AMG Car Sales Newcastle",
    caption: "",
  },
  {
    photo: "/Images/Architecture/BloomingDale/left side.jpg",
    title: "BloomingDale School, Badaun",
    caption: "",
  },
  {
    photo: "/Images/Architecture/BloomingDale/right side.jpg",
    title: "BloomingDale School, Badaun",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/1.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/3.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/ARCHI.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/ARCHI4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/546.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/FRONT FINAL.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/NIGHT.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/NIGHT1.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/PERSPECTIVE1.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/SIDE FINAL.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/4.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/5.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/11.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/222.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/3333.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/332233.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/21.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/1.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/2.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/FRONT.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/rough.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/latest.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/2.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/EEEE.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/EFEE.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/WWWW.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo:
      "/Images/Architecture/RampurFarmHouse/RH-EXPORT-RE-SIDENCE-Model.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/Brassworld.jpeg",
    title: "Brass World Export Factory",
    caption: "",
  },
  {
    photo: "/Images/Architecture/GovernmentHouse.jpeg",
    title: "Government House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/VIEW- HORIZON.jpg",
    title: "Horizon Enterprises",
    caption: "",
  },
];
