import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "./Services.css";
import architecture from "../GlobalImages/model-architecture.jpg";
import interior from "../GlobalImages/interiordesign.jpeg";

function Services() {
  return (
    <div>
      <Header />
      <div className="services">
        <h1 className="servicesHeading">Services</h1>
        <div className="serviceList">
          <div className="service">
            <h1 className="serviceName">Architecture</h1>
            <div className="serviceContent">
              <p className="serviceText">
                We provide architecture design services to meet your
                requirements and provide you with a bespoke solution.
              </p>
              <img src={architecture} alt="service" className="image" />
            </div>
          </div>
          <div className="service">
            <h1 className="serviceName">Interior Design</h1>
            <div className="serviceContent">
              <p className="serviceText">
                We provide interior design services to make the insides of your
                buildings a spectacle.
              </p>
              <img src={interior} alt="service" className="image" />
            </div>
          </div>
          <div className="service">
            <h1 className="serviceName">Construction Management</h1>
            <div className="serviceContent">
              <p className="serviceText">
                The management services we provide are of the highest quality
                and exactly what you need if you are requiring to complete the
                project with maximum efficiency and high standards.
              </p>
              <img src={architecture} alt="service" className="image" />
            </div>
          </div>
          <div className="service">
            <h1 className="serviceName">BIM</h1>
            <div className="serviceContent">
              <p className="serviceText">
                Building and Information Modelling services we provide ensure
                that your project is being handled in the most efficient way and
                has all the processes the modern engineering entails of.
              </p>
              <img src={architecture} alt="service" className="image" />
            </div>
          </div>
          <div className="service">
            <h1 className="serviceName">Product Design</h1>
            <div className="serviceContent">
              <p className="serviceText">
                We also provide bespoke designing services for a particular
                component that you may need designing.
              </p>
              <img src={architecture} alt="service" className="image" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Services;
