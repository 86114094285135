import Home from "./Pages/Home";
import { HashRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Services from "./Pages/Services";
import About from "./Pages/About";
import UpcomingWork from "./Pages/UpcomingWork";
import Contact from "./Pages/Contact";
import AMG from "./Pages/ProjectPages/Archi/AMG";
import Bloomingdale from "./Pages/ProjectPages/Archi/BloomingDale";
import Brassworld from "./Pages/ProjectPages/Archi/BrassWorld";
import Clinichouse from "./Pages/ProjectPages/Archi/ClinicHouse";
import GovernmentHouse from "./Pages/ProjectPages/Archi/GovernmentHouse";
import Horizon from "./Pages/ProjectPages/Archi/Horizon";
import JawadAlam from "./Pages/ProjectPages/Archi/JawadAlam";
import MarqueImplex from "./Pages/ProjectPages/Archi/MarqueImplex";
import PrivateResidence1 from "./Pages/ProjectPages/Archi/PrivateResidence1";
import PrivateResidence2 from "./Pages/ProjectPages/Archi/PrivateResidence2";
import Rampur from "./Pages/ProjectPages/Archi/Rampur";
import BharatBrass from "./Pages/ProjectPages/CM/BharatBrass";
import PR4 from "./Pages/ProjectPages/CM/PR4";
import DiningRoom from "./Pages/ProjectPages/ID/DiningRoom";
import EntraceFoyer from "./Pages/ProjectPages/ID/EntraceFoyer";
import EuropeFood from "./Pages/ProjectPages/ID/EuropeFood";
import GeneralClinic from "./Pages/ProjectPages/ID/GeneralClinic";
import InteriorOffice from "./Pages/ProjectPages/ID/InteriorOffice";
import ItalianStairs from "./Pages/ProjectPages/ID/ItalianStaircase";
import Lounge from "./Pages/ProjectPages/ID/Lounge";
import MadsH from "./Pages/ProjectPages/ID/MadsH";
import DentalClinic from "./Pages/ProjectPages/ID/DentalClinic";
import PR5 from "./Pages/ProjectPages/ID/PR5";
import ResidentialGardens from "./Pages/ProjectPages/ID/ResidentialGardens";
import Studio from "./Pages/ProjectPages/ID/StudioDesign";
import Estall from "./Pages/ProjectPages/PD/EStall";
import RedbrickHouse from "./Pages/ProjectPages/Archi/RedbrickHouse";
import Bedroom from "./Pages/ProjectPages/ID/Bedroom";

export const hashPaths: string[] = [
  "/",
  "/services",
  "/about",
  "/upcoming-work",
  "/contact",
  "/archi/AMG",
  "/archi/BloomingDale",
  "/archi/Brassworld",
  "/archi/ClinicHouse",
  "/archi/GovernmentHouse",
  "/archi/Horizon",
  "/archi/JA",
  "/archi/Marque",
  "/archi/PR1",
  "/archi/PR2",
  "/archi/Rampur",
  "/CM/BharatBrass",
  "/CM/PR4",
  "/ID/DentalClinic",
  "/ID/DiningRoom",
  "/ID/Entrace-Foyer",
  "/ID/EuropeFood",
  "/ID/GeneralClinic",
  "/ID/InteriorOffice",
  "/ID/ItalianStaircase",
  "/ID/Lounge",
  "/ID/MadsH",
  "/ID/PR5",
  "/ID/ResidentialGardens",
  "/ID/StudioDesign",
  "/PD/Estall",
  "/archi/RedBrickHouse",
  "/ID/BedroomInterior",
];
const RouterFunc = () => {
  return (
    <Router>
      <Routes>
        <Route path={hashPaths[0]} element={<Home />} />
        <Route path={hashPaths[1]} element={<Services />} />
        <Route path={hashPaths[2]} element={<About />} />
        <Route path={hashPaths[3]} element={<UpcomingWork />} />
        <Route path={hashPaths[4]} element={<Contact />} />
        <Route path={hashPaths[5]} element={<AMG />} />
        <Route path={hashPaths[6]} element={<Bloomingdale />} />
        <Route path={hashPaths[7]} element={<Brassworld />} />
        <Route path={hashPaths[8]} element={<Clinichouse />} />
        <Route path={hashPaths[9]} element={<GovernmentHouse />} />
        <Route path={hashPaths[10]} element={<Horizon />} />
        <Route path={hashPaths[11]} element={<JawadAlam />} />
        <Route path={hashPaths[12]} element={<MarqueImplex />} />
        <Route path={hashPaths[13]} element={<PrivateResidence1 />} />
        <Route path={hashPaths[14]} element={<PrivateResidence2 />} />
        <Route path={hashPaths[15]} element={<Rampur />} />
        <Route path={hashPaths[16]} element={<BharatBrass />} />
        <Route path={hashPaths[17]} element={<PR4 />} />
        <Route path={hashPaths[18]} element={<DentalClinic />} />
        <Route path={hashPaths[19]} element={<DiningRoom />} />
        <Route path={hashPaths[20]} element={<EuropeFood />} />
        <Route path={hashPaths[21]} element={<EuropeFood />} />
        <Route path={hashPaths[22]} element={<GeneralClinic />} />
        <Route path={hashPaths[23]} element={<InteriorOffice />} />
        <Route path={hashPaths[24]} element={<ItalianStairs />} />
        <Route path={hashPaths[25]} element={<Lounge />} />
        <Route path={hashPaths[26]} element={<MadsH />} />
        <Route path={hashPaths[27]} element={<PR5 />} />
        <Route path={hashPaths[28]} element={<ResidentialGardens />} />
        <Route path={hashPaths[29]} element={<Studio />} />
        <Route path={hashPaths[30]} element={<Estall />} />
        <Route path={hashPaths[31]} element={<RedbrickHouse />} />
        <Route path={hashPaths[32]} element={<Bedroom />} />
      </Routes>
    </Router>
  );
};

export default RouterFunc;
