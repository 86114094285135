import Header from "../Components/Header";
import "./Contact.css";
import phone from "../GlobalImages/phone.png";
import email from "../GlobalImages/email6.png";
import insta from "../GlobalImages/insta2.png";
import linkedin from "../GlobalImages/linkedin.png";

function Contact() {
  return (
    <div className="Contact">
      <Header />
      <h1 className="pageTitle">Contact</h1>
      <p className="contacttext">
        To find out more about how we can help you achieve your goal please get
        in touch and we will answer all your questions.
      </p>
      <div className="contactDiv">
        <div className="contactRow">
          <img src={email} alt="icon" className="contactIcon" />
          <p className="value">arish@madsarchitect.com</p>
        </div>

        <div className="contactRow">
          <img src={phone} alt="icon" className="contactIcon" />
          <p className="value">+447459690234 (UK)</p>
        </div>

        <div className="contactRow">
          <img src={phone} alt="icon" className="contactIcon" />
          <p className="value">+919871883946 (India)</p>
        </div>

        <div className="contactRow">
          <img src={linkedin} alt="icon" className="contactIcon" />
          <a
            className="logoLinks"
            href="https://www.linkedin.com/in/mohammad-arish"
          >
            <button className="linkedinButton">LinkedIn</button>
          </a>
        </div>

        <div className="contactRow">
          <img src={insta} alt="icon" className="contactIcon" />
          <a className="logoLinks" href="https://www.instagram.com/m.arishds/">
            <button className="instaButton">Insta</button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Contact;
