import { paths } from "../Components/Header";
import insta from "../GlobalImages/insta.png";
import linkedin from "../GlobalImages/linkedin.png";
import "../Components/Footer.css";
import { useEffect } from "react";
import Aos from "aos";

function Footer() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <footer>
      <div className="nameInfo">
        <h1>MADS Architects</h1>
        <h2>+447459690234</h2>
        <h2>+919871883946</h2>
        <h2>arish@madsarchitect.com</h2>
      </div>
      <div className="pageLinks">
        <a href={paths[0]}>Home</a>
        <a href={paths[1]}>Services</a>
        <a href={paths[2]}>About</a>
        <a href={paths[3]}>Upcoming Work</a>
        <a href={paths[4]}>Contact</a>
      </div>
      <div className="socialmedia">
        <a href="https://www.linkedin.com/in/mohammad-arish">
          <img src={linkedin} alt="linkedinLogo" className="footerLogo" />
        </a>
        <a href="https://www.instagram.com/m.arishds/">
          <img src={insta} alt="instaLogo" className="footerLogo" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
