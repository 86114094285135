import { useState } from "react";
import "./Slider.css";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";

type SliderProp = {
  imagelist: string[];
};

function Slider(props: SliderProp) {
  const [current, setCurrent] = useState<number>(0);

  const nextSlide = () => {
    setCurrent(current === props.imagelist.length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? props.imagelist.length - 1 : current - 1);
  };

  return (
    <div className="slider">
      {props.imagelist.length > 1 && (
        <>
          <BsFillArrowLeftCircleFill
            className="left-arrow"
            onClick={prevSlide}
          />
          <BsFillArrowRightCircleFill
            className="right-arrow"
            onClick={nextSlide}
          />
        </>
      )}

      {props.imagelist.map((slide, index) => {
        return (
          <div>
            {" "}
            <div className={index === current ? "activeslide" : "slide"}>
              {index === current && (
                <img src={slide} className="slideimage" alt="slide" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Slider;
