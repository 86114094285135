import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "./UpcomingWork.css";
import architecture from "../GlobalImages/model-architecture.jpg";
import interior from "../GlobalImages/interiordesign.jpeg";

function UpcomingWork() {
  return (
    <div>
      <Header />
      <div className="upcomingWorks">
        <h1 className="pageHeading">Upcoming Projects</h1>
        <div className="worksList">
          <div className="work">
            <h1 className="workName">MADS House</h1>
            <div className="workContent">
              <p className="workText">DESCRIPTION</p>
              <img src={architecture} alt="work" className="image" />
            </div>
          </div>
          <div className="work">
            <h1 className="workName">MADS office</h1>
            <div className="workContent">
              <p className="workText">DESCRIPTION</p>
              <img src={interior} alt="work" className="image" />
            </div>
          </div>
          <div className="work">
            <h1 className="workName">FUTOUSH RESTAURANT</h1>
            <div className="workContent">
              <p className="workText">DESCRIPTION</p>
              <img src={architecture} alt="work" className="image" />
            </div>
          </div>
          <div className="work">
            <h1 className="workName">BENWICK HOUSE</h1>
            <div className="workContent">
              <p className="workText">DESCRIPTION</p>
              <img src={architecture} alt="work" className="image" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default UpcomingWork;
