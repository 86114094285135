import "./Dropdown.css";
import { useNavigate } from "react-router-dom";
function Dropdown(this: any) {
  const navigate = useNavigate();

  function redirect(value: any) {
    navigate(value.target.value);
  }
  return (
    <div className="custom-selector">
      <select onChange={redirect} className="custom-select">
        <option className="menu" value="" disabled selected>
          MENU
        </option>
        <option value="/">HOME</option>
        <option value="/Services">SERVICES</option>
        <option value="/About">ABOUT</option>
        <option value="/upcoming-work">UPCOMING WORK</option>
        <option value="/Contact">CONTACT</option>
      </select>
    </div>
  );
}

export default Dropdown;
