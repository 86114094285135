import { ImageObject } from "../Types";

export const workImages: ImageObject[] = [
  {
    photo: "/Images/Architecture/RedbrickHouse/three.jpg",
    title: "Red Brick House",
    caption: "/archi/RedBrickHouse",
  },
  {
    photo: "/Images/Interior Design/BedroomInterior/one.jpg",
    title: "Bedroom Interior",
    caption: "/ID/BedroomInterior",
  },

  {
    photo: "/Images/Architecture/Marque/Marque.jpeg",
    title: "Marque Implex Factory Noorpoor",
    caption: "/archi/Marque",
  },
  {
    photo: "/Images/Construction Management/BharatBrass/BharatBrassReal.jpeg",
    title: "Site managed for Bharat Brass",
    caption: "/CM/BharatBrass",
  },
  {
    photo: "/Images/Interior Design/AdnanResidence/1.jpeg",
    title: "Interior Design for Private Residence",
    caption: "/ID/PR5",
  },
  {
    photo: "/Images/Product Design/Estall/Estall1.jpg",
    title: "Estall Designed",
    caption: "/PD/Estall",
  },
  {
    photo: "/Images/Architecture/AMG-Car/VIEW 1.jpg",
    title: "AMG Car Sales Newcastle",
    caption: "/archi/AMG",
  },
  {
    photo: "/Images/Construction Management/HassanResidence/Real.jpeg",
    title: "Site for private residence managed",
    caption: "/CM/PR4",
  },
  {
    photo: "/Images/Interior Design/Entrance-Foyer/Entrance-Foyer-Design1.jpeg",
    title: "Interior Design-Entrace Foyer",
    caption: "/ID/Entrace-Foyer",
  },
  {
    photo: "/Images/Architecture/BloomingDale/left side.jpg",
    title: "BloomingDale School, Badaun",
    caption: "/archi/BloomingDale",
  },
  {
    photo: "/Images/Interior Design/DiningRoom/1F.jpg",
    title: "Interior Design for a dining room",
    caption: "/ID/DiningRoom",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "/archi/ClinicHouse",
  },
  {
    photo: "/Images/Interior Design/ItalianMoraccon/model.jpg",
    title: "Italian Moraccon Stair Design",
    caption: "/ID/ItalianStaircase",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/546.jpg",
    title: "Private Residence",
    caption: "/archi/JA",
  },
  {
    photo: "/Images/Interior Design/MadsHouse/5.jpeg",
    title: "Interior Design & Construction for residence",
    caption: "/ID/MadsH",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/5.jpg",
    title: "Private Residence",
    caption: "/archi/PR1",
  },
  {
    photo: "/Images/Interior Design/Paradise/6.jpeg",
    title: "Interior Design and build for a lounge",
    caption: "/ID/Lounge",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/21.jpg",
    title: "Private Residence",
    caption: "/archi/PR2",
  },
  {
    photo: "/Images/Interior Design/ResidentialGardens/TERR.jpg",
    title: "Design for Residential Gardens",
    caption: "/ID/ResidentialGardens",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/latest.jpg",
    title: "Rampur Farm House",
    caption: "/archi/Rampur",
  },
  {
    photo: "/Images/Architecture/Brassworld/Brassworld.jpeg",
    title: "Brass World Export Factory",
    caption: "/archi/Brassworld",
  },
  {
    photo: "/Images/Architecture/GovernmentHouse/GovernmentHouse.jpeg",
    title: "Government House",
    caption: "/archi/GovernmentHouse",
  },
  {
    photo: "/Images/Architecture/Horizon/VIEW- HORIZON.jpg",
    title: "Horizon Enterprises",
    caption: "/archi/Horizon",
  },

  {
    photo: "/Images/Interior Design/DentalClinic/V3.jpg",
    title: "Interior Design for Dental Clinic",
    caption: "/ID/DentalClinic",
  },

  {
    photo: "/Images/Interior Design/GeneralClinic/1.jpg",
    title: "Interior Design for a general clinic",
    caption: "/ID/GeneralClinic",
  },

  {
    photo: "/Images/Interior Design/MadsOffice/4.jpeg",
    title: "Interior Design for an office",
    caption: "/ID/InteriorOffice",
  },

  {
    photo: "/Images/Interior Design/Studio/3.jpg",
    title: "Interior Design for a studio",
    caption: "/ID/StudioDesign",
  },
  {
    photo: "/Images/Interior Design/EuropeFood/EuropeFoodLimited.jpg",
    title: "Office designed for Europe Food Ltd",
    caption: "/ID/EuropeFood",
  },
];
