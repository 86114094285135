import { ImageObject } from "../Types";

export const workImages: ImageObject[] = [
  {
    photo: "/Images/Architecture/Marque.jpeg",
    title: "Marque Implex Factory Noorpoor",
    caption: "",
  },
  {
    photo: "/Images/Architecture/AMG-Car/VIEW 1.jpg",
    title: "AMG Car Sales Newcastle",
    caption: "",
  },
  {
    photo: "/Images/Architecture/BloomingDale/left side.jpg",
    title: "BloomingDale School, Badaun",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/546.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/5.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/21.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/latest.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/Brassworld.jpeg",
    title: "Brass World Export Factory",
    caption: "",
  },
  {
    photo: "/Images/Architecture/GovernmentHouse.jpeg",
    title: "Government House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/VIEW- HORIZON.jpg",
    title: "Horizon Enterprises",
    caption: "",
  },
  {
    photo: "/Images/Construction Management/BharatBrass/BharatBrassReal.jpeg",
    title: "Site managed for Bharat Brass",
    caption: "",
  },
  {
    photo: "/Images/Construction Management/HassanResidence/Real.jpeg",
    title: "Site for private residence managed",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/AdnanResidence/1.jpeg",
    title: "Interior Design for Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/DentalClinic/V3.jpg",
    title: "Interior Design for Dental Clinic",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/DiningRoom/1F.jpg",
    title: "Interior Design for a dining room",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/Entrance-Foyer/Entrance-Foyer-Design1.jpeg",
    title: "Interior Design-Entrace Foyer",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/GeneralClinic/1.jpg",
    title: "Interior Design for a general clinic",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/ItalianMoraccon/model.jpg",
    title: "Italian Moraccon Stair Design",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/MadsHouse/5.jpeg",
    title: "Interior Design and Construction for private residence",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/MadsOffice/4.jpeg",
    title: "Interior Design for an office",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/Paradise/6.jpeg",
    title: "Interior Design and build for a lounge",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/ResidentialGardens/TERR.jpg",
    title: "Design for Residential Gardens",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/Studio/3.jpg",
    title: "Interior Design for a studio",
    caption: "",
  },
  {
    photo: "/Images/Interior Design/EuropeFoodLimited.jpg",
    title: "Office designed for Europe Food Ltd",
    caption: "",
  },
  {
    photo: "/Images/Product Design/Estall/Estall1.jpg",
    title: "Office designed for Europe Food Ltd",
    caption: "",
  },
];
