import { ImageObject } from "../Types";

export const workImages: ImageObject[] = [
  {
    photo: "/Images/Architecture/Marque.jpeg",
    title: "Marque Implex Factory Noorpoor",
    caption: "",
  },
  {
    photo: "/Images/Architecture/AMG-Car/VIEW 1.jpg",
    title: "AMG Car Sales Newcastle",
    caption: "",
  },
  {
    photo: "/Images/Architecture/AMG-Car/VIEW 1.jpg",
    title: "AMG Car Sales Newcastle",
    caption: "",
  },
  {
    photo: "/Images/Architecture/BloomingDale/left side.jpg",
    title: "BloomingDale School, Badaun",
    caption: "",
  },
  {
    photo: "/Images/Architecture/BloomingDale/left side.jpg",
    title: "BloomingDale School, Badaun",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/ClinicHouse/4.jpg",
    title: "Clinic House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/JawadAlamHouse/546.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/LajpatNagar/5.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/PrivateResidence/21.jpg",
    title: "Private Residence",
    caption: "",
  },
  {
    photo: "/Images/Architecture/RampurFarmHouse/latest.jpg",
    title: "Rampur Farm House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/Brassworld.jpeg",
    title: "Brass World Export Factory",
    caption: "",
  },
  {
    photo: "/Images/Architecture/GovernmentHouse.jpeg",
    title: "Government House",
    caption: "",
  },
  {
    photo: "/Images/Architecture/VIEW- HORIZON.jpg",
    title: "Horizon Enterprises",
    caption: "",
  },
];
